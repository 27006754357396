import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrimaryButtonComponent } from '../../../shared/components/primary-button/primary-button.component';
import { CourseEnrollmentContainerComponent } from './course-enrollment-container/course-enrollment-container.component';
import { MatIcon } from '@angular/material/icon';
import { UserCreationContainerComponent } from '../../../domains/user/components/user-creation-container/user-creation-container.component';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { UserCsvUploadContainerComponent } from '../../../domains/user/components/user-csv-upload-container/user-csv-upload-container.component';
import { UserCreationNotificationService } from '../../../domains/user/services/user-creation-notification.service';

export enum DialogSteps {
  ENROLL = 'enroll',
  CREATE = 'create',
  CSV = 'csv',
}

@Component({
  selector: 'app-course-enrollment-user-creation-dialog-container',
  standalone: true,
  imports: [
    PrimaryButtonComponent,
    CourseEnrollmentContainerComponent,
    UserCreationContainerComponent,
    MatIcon,
    UserCsvUploadContainerComponent,
  ],
  templateUrl: './course-enrollment-user-creation-dialog-container.component.html',
})
export class CourseEnrollmentUserCreationDialogContainerComponent implements OnInit, OnDestroy {
  step: string;
  dialogSteps = DialogSteps;
  private readonly destroy$: Subject<void> = new Subject();
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly userCreationNotificationService: UserCreationNotificationService,
    public dialogRef: MatDialogRef<CourseEnrollmentUserCreationDialogContainerComponent>,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((params) => params['step'] as DialogSteps),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((step) => {
        this.step = step;
      });
  }

  showCourseEnrollmentContent() {
    void this.router.navigate([], { queryParams: { step: DialogSteps.ENROLL } });
  }

  showUserCreationContent() {
    void this.router.navigate([], { queryParams: { step: DialogSteps.CREATE } });
  }

  showCsvUploadContent() {
    void this.router.navigate([], { queryParams: { step: DialogSteps.CSV } });
  }

  goBack() {
    const currentRoute = this.router.url;
    if (currentRoute.includes('/portal/settings/')) {
      this.closeDialog();
    } else {
      this.showCourseEnrollmentContent();
    }
  }

  handleUserSuccessfullyCreatedEvent() {
    this.userCreationNotificationService.setUserChangedNotification();
    this.goBack();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

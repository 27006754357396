export class UserDTO {
  firstName: string;
  lastName: string;
  email: string;
}

export enum LicenseStatus {
  CONSUMED = 'Consumed',
  RESERVED = 'Reserved',
}

export class UserResponseDTO extends UserDTO {
  id: string;
  licenses: Array<{ status: LicenseStatus; id: string; tenantId: string; userId: string }>;
  accountEnabled: boolean;
  createdAt: string;
}

export class UsersResponseDTO {
  users: UserResponseDTO[];
}

enum Status {
  SUCCESS = 'success',
  ERROR = 'error',
}

enum Reason {
  MALFORMED = 'malformed',
  DUPLICATE = 'duplicate',
}

class UserCreationResult {
  id: string;
  email: string;
  status?: Status;
  reason?: Reason;
}

export class UserCreationRequestDTO {
  users: UserDTO[];
}

export class UserCreationResponseDTO {
  users: UserCreationResult[];
}

export class UserDisableEnableRequestDTO {
  users: Array<{ id: string }>;
}

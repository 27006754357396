import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ButtonRowComponent } from '../../../../shared/components/button-row/button-row.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { UserCsvHeader, UserCsvUploadService } from '../../services/user-csv-upload.service';
import { Column, SimpleTableComponent } from '../../../../shared/components/simple-table/simple-table.component';
import { TextButtonComponent, ButtonColor } from '../../../../shared/components/text-button/text-button.component';
import { CommonModule } from '@angular/common';
import { DragOverDirective } from '../../../../shared/directives/drag-over.directive';
import { LanguageService } from '../../../../core/services/language.service';
import { LinkComponent } from '../../../../shared/components/link/link.component';
import { UserService } from '../../services/user.service';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { easeInAnimation } from '../../../../shared/animations/ease-in-animation';
import { ValidationResult } from '../../../../core/services/csv-upload.service';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-user-csv-upload-container',
  standalone: true,
  imports: [
    ButtonRowComponent,
    TranslocoDirective,
    MatIconModule,
    SimpleTableComponent,
    TextButtonComponent,
    CommonModule,
    DragOverDirective,
    LinkComponent,
  ],
  templateUrl: './user-csv-upload-container.component.html',
  styleUrl: './user-csv-upload-container.component.css',
  animations: [easeInAnimation],
})
export class UserCsvUploadContainerComponent {
  @Output() usersSuccessfullyCreatedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() backButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('status', { static: true }) status!: TemplateRef<{
    $implicit: string;
  }>;

  parsedUsers:
    | Array<{
        firstName: string;
        lastName: string;
        email: string;
        validation?: ValidationResult;
      }>
    | undefined = undefined;
  columns: Array<Column<string | { $implicit: string }>> = [
    {
      headerName: this.getTranslation(UserCsvHeader.FIRST_NAME),
      field: UserCsvHeader.FIRST_NAME,
    },
    {
      headerName: this.getTranslation(UserCsvHeader.LAST_NAME),
      field: UserCsvHeader.LAST_NAME,
    },
    {
      headerName: this.getTranslation(UserCsvHeader.EMAIL),
      field: UserCsvHeader.EMAIL,
    },
    { headerName: this.getTranslation('status'), renderComponent: undefined },
  ];
  buttonColor = ButtonColor;
  isLoading = false;
  inputElement: HTMLInputElement;
  fileName: string;
  invalidUserCount: number;

  constructor(
    private readonly userCsvUploadService: UserCsvUploadService,
    private readonly snackbarService: SnackbarService,
    private readonly languageService: LanguageService,
    private readonly userService: UserService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  clearData() {
    this.parsedUsers = undefined;
    this.invalidUserCount = 0;
  }

  clearInput() {
    this.inputElement.value = '';
  }

  onFileSelected(changeEvent: Event) {
    const file = this.getFile(changeEvent);
    if (!file) {
      console.error('File is missing');
      return;
    }
    this.fileName = file.name;

    this.userCsvUploadService
      .parseCsv(file)
      .then((results: { data: Array<Record<string, string>>; validationResults: ValidationResult[] }) => {
        this.handleCsvResults(results);
        this.clearInput();
      })
      .catch((err: Error) => {
        this.clearInput();
        this.snackbarService.error('portal.users.creation.csvUpload.errors.' + err.message, false);
      });
  }

  private handleCsvResults(results: { data: Array<Record<string, string>>; validationResults: ValidationResult[] }) {
    const mergedResults = results.data.map((row, index) => {
      const validationResult = results.validationResults.find((result) => result.rowIndex === index);
      return {
        data: row,
        validation: validationResult,
      };
    });
    this.columns[3].renderComponent = this.status;
    this.parsedUsers = this.mapToParsedUsers(this.sortResultsByErrors(mergedResults));
    this.invalidUserCount = this.parsedUsers.filter((user) => user.validation?.isValid === false).length;
    if (this.invalidUserCount === 0) {
      this.analyticsService.track('csv_parsing_success');
    }
  }

  createUsers() {
    this.isLoading = true;
    this.userService.createUsers(this.parsedUsers?.map(({ validation: _validation, ...user }) => user)).subscribe({
      next: () => {
        this.isLoading = false;
        this.analyticsService.track('users_created_through_csv_upload');
        /* implement in future ticket:
            case: > 0 users not created - e.g. because of duplicates - -> show table */
        this.usersSuccessfullyCreatedEvent.emit();
      },
      error: (error: CustomPortalError) => {
        this.isLoading = false;
        this.snackbarService.error(error.errorType);
        this.clearData();
        this.clearInput();
      },
      complete: () => {
        this.snackbarService.success('portal.users.creation.successfulCreationSnackbar', {
          count: this.parsedUsers?.length,
        });
      },
    });
  }

  handleBackButtonClick() {
    this.backButtonClickEvent.emit();
  }

  private getTranslation(key: string): string {
    return this.languageService.translateOnRuntime('portal.users.creation.form.' + key);
  }

  private getFile(changeEvent: Event): File | undefined {
    this.inputElement = changeEvent.target as HTMLInputElement;

    if (!this.inputElement.files?.length) {
      return undefined;
    }
    const [file] = Array.from(this.inputElement.files);
    return file;
  }

  private sortResultsByErrors(
    mergedResults: Array<{
      data: Record<string, string>;
      validation: ValidationResult | undefined;
    }>,
  ) {
    return mergedResults.sort((a, b) => {
      const errorsA = a.validation?.errors?.length ?? 0;
      const errorsB = b.validation?.errors?.length ?? 0;
      return errorsB - errorsA;
    });
  }

  private mapToParsedUsers(
    sortedResults: Array<{
      data: Record<string, string>;
      validation: ValidationResult | undefined;
    }>,
  ) {
    return sortedResults.map(({ data, validation }) => ({
      firstName: data[UserCsvHeader.FIRST_NAME],
      lastName: data[UserCsvHeader.LAST_NAME],
      email: data[UserCsvHeader.EMAIL],
      validation,
    }));
  }
}

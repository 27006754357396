import { Injectable } from '@angular/core';
import { CsvUploadService, ValidationResult, Validator } from '../../../core/services/csv-upload.service';
import { emailValidationPattern } from '../../../shared/validators/custom-validators';

export enum UserCsvHeader {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

@Injectable({
  providedIn: 'root',
})
export class UserCsvUploadService {
  private readonly validators: Validator[] = [
    { fieldName: UserCsvHeader.FIRST_NAME, required: true },
    { fieldName: UserCsvHeader.LAST_NAME, required: true },
    { fieldName: UserCsvHeader.EMAIL, required: true, validatorFn: this.validateEmail },
  ];

  constructor(private readonly csvUploadService: CsvUploadService) {}

  private validateEmail(value: string) {
    const emailPattern = new RegExp(emailValidationPattern);

    return emailPattern.test(value);
  }

  public async parseCsv(
    file: File,
  ): Promise<{ data: Array<Record<string, string>>; validationResults: ValidationResult[] }> {
    return this.csvUploadService.parseCsvFromUploadedFile(file, this.validators);
  }
}

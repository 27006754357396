import { Component } from '@angular/core';
import { CourseInstancesOverviewComponent } from '../../../domains/courses/components/course-instances-overview/course-instances-overview.component';
import { TranslocoModule } from '@jsverse/transloco';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [CourseInstancesOverviewComponent, TranslocoModule, MatDialogContent, MatDialogActions, AsyncPipe],
  animations: [easeInAnimationFast],
  templateUrl: './overview-container.component.html',
  styleUrl: './overview-container.component.css',
})
export class OverviewContainerComponent {}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseStatus } from '../../models/SubscriptionDTO';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { SepBarChartComponent } from '../../../../shared/components/sep-bar-chart/sep-bar-chart.component';
import { LicenseCalculationsService } from '../../services/license-calculations.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-license-overview-card',
  standalone: true,
  imports: [MatIconModule, AsyncPipe, SepBarChartComponent, MatTooltipModule, TranslocoModule],
  templateUrl: './license-overview-card.component.html',
})
export class LicenseOverviewCardComponent implements OnInit {
  licenses$: Observable<Array<{ id: string; status: LicenseStatus }>>;
  assignedLicensesCount$: Observable<number>;
  reservedLicensesCount$: Observable<number>;
  availableLicensesCount$: Observable<number>;
  totalLicensesCount$: Observable<number>;
  availableToTotalLicensesRatio$: Observable<number>;

  constructor(private readonly licenseCalculationsService: LicenseCalculationsService) {}

  ngOnInit(): void {
    this.assignedLicensesCount$ = this.licenseCalculationsService.getAssignedLicensesCount();
    this.reservedLicensesCount$ = this.licenseCalculationsService.getReservedLicensesCount();
    this.availableLicensesCount$ = this.licenseCalculationsService.getAvailableLicensesCount();
    this.totalLicensesCount$ = this.licenseCalculationsService.getTotalLicensesCount();
    this.availableToTotalLicensesRatio$ = this.licenseCalculationsService.getAvailableToTotalLicensesRatio();
  }
}

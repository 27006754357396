import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CourseDataService {
  private courseId: string | undefined = undefined;

  constructor() {}

  getCourseId(): string | undefined {
    return this.courseId;
  }

  setCourseId(id: string): void {
    this.courseId = id;
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { CourseProgress } from '../../models/CourseProgress';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { EnrolledStudentUIModel } from '../../models/StudentUIModel';

@Component({
  selector: 'app-course-statistics',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './course-statistics.component.html',
  styleUrl: './course-statistics.component.css',
})
export class CourseStatisticsComponent implements OnChanges {
  @Input({ required: true }) students: EnrolledStudentUIModel[];
  numberOfEnrolledStudents: number;
  numberOfStudentsCourseStarted: number;
  numberOfStudentsCourseCompleted: number;

  ngOnChanges(): void {
    this.numberOfEnrolledStudents = this.students.length;
    this.numberOfStudentsCourseStarted = this.students.filter(
      (student) => student.courseProgress === CourseProgress.STARTED,
    ).length;
    this.numberOfStudentsCourseCompleted = this.students.filter(
      (student) => student.courseProgress === CourseProgress.COMPLETED,
    ).length;
  }
}

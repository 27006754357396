import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SepTableComponent } from '../../../../shared/components/sep-table/sep-table.component';
import { TableBulkAction, TableRowAction } from '../../../../shared/components/sep-table/Action';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCardComponent } from '../../../../shared/components/user-card/user-card.component';
import { HashMap, TranslocoDirective } from '@jsverse/transloco';
import { LanguageService } from '../../../../core/services/language.service';
import { Column } from '../../../../shared/components/sep-table/Column';
import { DialogModel } from '../../../../shared/components/dialog/dialog';
import { MatDialog } from '@angular/material/dialog';
import { CourseEnrollmentNotificationService } from '../../services/course-enrollment-notification.service';
import { CourseService } from '../../services/course.service';
import { CourseDataService } from '../../services/course-data.service';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { EnrolledStudentUIModel } from '../../models/StudentUIModel';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { FilterCategory } from '../../../../shared/components/sep-filter/sep-filter.component';

@Component({
  selector: 'app-enrolled-student-table',
  standalone: true,
  imports: [SepTableComponent, UserCardComponent, TranslocoDirective],
  templateUrl: './enrolled-student-table.component.html',
  styleUrl: './enrolled-student-table.component.css',
})
export class EnrolledStudentTableComponent implements AfterViewInit, OnChanges {
  @ViewChild('studentCard', { static: true }) studentCard!: TemplateRef<{ $implicit: string }>;
  @Input({ required: true }) students: EnrolledStudentUIModel[];
  dataSource: EnrolledStudentUIModel[] = [];

  columns: Array<Column<EnrolledStudentUIModel, string | { $implicit: string }>> = [
    {
      headerName: this.getTranslation('users.table.header.nameAndEmail'),
      renderComponent: undefined,
      key: 'username',
    },
    {
      headerName: this.getTranslation('users.table.header.status'),
      field: 'courseProgress',
      key: 'courseProgress',
    },
  ];

  bulkActions: Array<TableBulkAction<EnrolledStudentUIModel>> = [
    {
      label: this.getTranslation('courses.unenrollment.bulkUnenrollButtonText'),
      icon: 'group_remove',
      executeAction: (targetedStudents: EnrolledStudentUIModel[]) => {
        this.showCourseUnenrollmentConfirmationDialog(targetedStudents);
      },
    },
    {
      label: this.getTranslation('courses.reminders.bulkSendReminder'),
      icon: 'send',
      executeAction: (targetedStudents: EnrolledStudentUIModel[]) => {
        this.showSendReminderConfirmationDialog(targetedStudents);
      },
    },
  ];

  rowActions: Array<TableRowAction<EnrolledStudentUIModel>> = [
    {
      label: this.getTranslation('courses.unenrollment.unenrollButtonText'),
      icon: 'person_remove',
      executeAction: (targetedStudents: EnrolledStudentUIModel) => {
        this.showCourseUnenrollmentConfirmationDialog([targetedStudents]);
      },
    },
    {
      label: this.getTranslation('courses.reminders.sendReminder'),
      icon: 'send',
      executeAction: (targetedStudents: EnrolledStudentUIModel) => {
        this.showSendReminderConfirmationDialog([targetedStudents]);
      },
    },
  ];

  filterCategories: FilterCategory[] | undefined;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
    private readonly dialog: MatDialog,
    private readonly courseEnrollmentNotificationService: CourseEnrollmentNotificationService,
    private readonly courseService: CourseService,
    private readonly courseDataService: CourseDataService,
    private readonly snackbarService: SnackbarService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngAfterViewInit(): void {
    this.columns[0].renderComponent = this.studentCard;
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['students'].currentValue !== changes['students'].previousValue) {
      this.dataSource = this.translateProperties(changes['students'].currentValue as EnrolledStudentUIModel[]);
      this.initializeCourseProgressFilter();
    }
  }

  showCourseEnrollmentDialog() {
    this.analyticsService.track('add_participants_clicked');
    void this.router.navigate(['dialog'], { relativeTo: this.activatedRoute, queryParams: { step: 'enroll' } });
  }

  private showSendReminderConfirmationDialog(targetedStudents: EnrolledStudentUIModel[]) {
    const reminderSubject =
      targetedStudents.length === 1
        ? targetedStudents[0].username
        : this.getTranslation('courses.reminders.allSelectedText');
    const reminderDialog = new DialogModel(this.dialog);
    reminderDialog.closable = true;
    reminderDialog.headline = this.getTranslation('courses.reminders.sendReminderConfirmationHeadline');
    reminderDialog.description = this.getTranslation('courses.reminders.sendReminderConfirmationText', {
      reminderSubject,
    });
    reminderDialog.buttonRowData = {
      confirmButtonText: this.getTranslation('courses.reminders.sendReminderButtonText'),
      confirmButtonAction: () => {
        this.sendReminder(targetedStudents);
      },
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };

    reminderDialog.openDialog();
  }

  private showCourseUnenrollmentConfirmationDialog(targetedStudents: EnrolledStudentUIModel[]) {
    const unenrollmentSubject =
      targetedStudents.length === 1
        ? targetedStudents[0].username
        : this.getTranslation('courses.unenrollment.allSelectedText');

    const unenrollmentConfirmationDialog = new DialogModel(this.dialog);
    unenrollmentConfirmationDialog.closable = true;
    unenrollmentConfirmationDialog.headline = this.getTranslation(
      'courses.unenrollment.unenrollmentConfirmationHeadline',
    );
    unenrollmentConfirmationDialog.description = this.getTranslation(
      'courses.unenrollment.unenrollmentConfirmationText',
      { unenrollmentSubject },
    );
    unenrollmentConfirmationDialog.buttonRowData = {
      confirmButtonText: this.getTranslation('courses.unenrollment.unenrollButtonText'),
      confirmButtonAction: () => {
        this.unenrollStudents(targetedStudents);
      },
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };

    unenrollmentConfirmationDialog.openDialog();
  }

  private sendReminder(students: EnrolledStudentUIModel[]) {
    const courseId = this.courseDataService.getCourseId();
    if (!courseId) {
      console.error('No given course Id');
      return;
    }
    this.courseService.sendReminderToStudentsFromCourse(students, courseId).subscribe({
      next: () => {
        this.analyticsService.track('course_reminder_sent', {
          courseId: courseId,
          students: students.map(({ id }) => ({ id })),
        });
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  private unenrollStudents(students: EnrolledStudentUIModel[]) {
    const courseId = this.courseDataService.getCourseId();
    if (!courseId) {
      console.error('No given course Id');
      return;
    }
    this.courseService.unenrollStudentsFromCourse(students, courseId).subscribe({
      next: () => {
        this.analyticsService.track('students_unenrolled', {
          courseId: courseId,
          students: students.map(({ id }) => ({ id })),
        });
        this.courseEnrollmentNotificationService.setEnrollmentChanged();
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  translateProperties(students: EnrolledStudentUIModel[]): EnrolledStudentUIModel[] {
    return students.map((student) => ({
      ...student,
      courseProgress: this.getTranslation('courses.analytics.courseProgress.' + student.courseProgress),
    }));
  }

  private initializeCourseProgressFilter(): void {
    const uniqueCourseProgressValues = Array.from(new Set(this.students.map((student) => student.courseProgress)));

    if (uniqueCourseProgressValues.length <= 1) {
      return;
    }

    this.filterCategories = [
      {
        label: this.getTranslation('users.table.header.status'),
        value: 'courseProgress',
        options: uniqueCourseProgressValues.map((value) => ({
          label: this.getTranslation('courses.analytics.courseProgress.' + value),
          selected: true,
        })),
      },
    ];
  }

  private getTranslation(key: string, params?: HashMap): string {
    return this.languageService.translateOnRuntime('portal.' + key, params);
  }

  exportTableContentsToCsv() {
    const courseId = this.courseDataService.getCourseId();
    if (!courseId) {
      console.error('No given course Id');
      return;
    }
    this.courseService.exportStudentInfoToCsv(courseId).subscribe((data) => {
      this.openFile(data);
    });
  }

  private openFile(data: Blob) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'course-history.csv';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}

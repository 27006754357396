import { DatePipe } from '@angular/common';
import { UserCreationRequestDTO, UserDisableEnableRequestDTO, UserDTO, UserResponseDTO } from '../models/UserDTO';
import { UserUIModel } from '../models/UserUIModel';
import { environment } from '../../../../environments/environment';

function sanitizeUserFormat(user: { firstName: string; lastName: string; email: string }) {
  const toFirstCharUppercase = (name: string): string => {
    if (name.length === 0) {
      return name;
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const capitalizeWords = (name: string): string =>
    name
      .split(' ')
      .map((word) => toFirstCharUppercase(word))
      .join(' ');

  const sanitizedUser = {
    firstName: capitalizeWords(user.firstName),
    lastName: capitalizeWords(user.lastName),
    email: user.email.toLowerCase(),
  };

  return sanitizedUser;
}

export function formatDate(date: string): string | null {
  const fallbackLocale = environment.general.internationalFallbackLanguage;
  const locale = navigator.language;

  try {
    const datePipe = new DatePipe(locale);
    return datePipe.transform(date, 'yyyy');
  } catch {
    const fallbackDatePipe = new DatePipe(fallbackLocale);
    return fallbackDatePipe.transform(date, 'yyyy');
  }
}

export function transformUserFormDataToUserDTO(userFormData: Partial<UserDTO>): UserDTO {
  if (userFormData.firstName === undefined || userFormData.lastName === undefined || userFormData.email === undefined) {
    throw new Error();
  }
  return {
    firstName: userFormData.firstName,
    lastName: userFormData.lastName,
    email: userFormData.email,
  };
}

export function toUsersCreationRequestDTO(
  users: Array<{ firstName: string; lastName: string; email: string }> | undefined,
): UserCreationRequestDTO {
  if (users === undefined) {
    throw new Error();
  }
  const userCreationRequestDTO = new UserCreationRequestDTO();
  userCreationRequestDTO.users = users.map(sanitizeUserFormat);
  return userCreationRequestDTO;
}

export function toUserDisableEnableRequestDTO(users: UserUIModel[]): UserDisableEnableRequestDTO {
  const userDisableEnableRequestDTO = new UserDisableEnableRequestDTO();
  userDisableEnableRequestDTO.users = users.map((user) => ({ id: user.id }));
  return userDisableEnableRequestDTO;
}

export function toUserUIModel(users: UserResponseDTO[]): UserUIModel[] {
  return users.map((user) => {
    const username = `${user.firstName} ${user.lastName}`;
    const { email, id, licenses, accountEnabled, createdAt } = user;

    // for the MMP we assume that there is either one license per user (index 0 of licenses) or licenses are null
    const license = user.licenses.length ? { status: licenses[0].status, id: licenses[0].id } : null;

    return {
      id,
      username,
      email,
      accountEnabled,
      createdAt: createdAt ? formatDate(createdAt) : null,
      license,
    };
  });
}

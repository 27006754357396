import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UserCardComponent } from '../../../../shared/components/user-card/user-card.component';
import { SepTableComponent, SepTableType } from '../../../../shared/components/sep-table/sep-table.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { LanguageService } from '../../../../core/services/language.service';
import { Column } from '../../../../shared/components/sep-table/Column';
import { BadgeComponent } from '../../../../shared/components/badge/badge.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StudentEligibleForEnrollmentUIModel } from '../../models/StudentUIModel';

@Component({
  selector: 'app-course-enrollment-table',
  standalone: true,
  imports: [UserCardComponent, SepTableComponent, TranslocoDirective, BadgeComponent, MatIconModule, MatTooltipModule],
  templateUrl: './course-enrollment-table.component.html',
  styleUrl: './course-enrollment-table.component.css',
})
export class CourseEnrollmentTableComponent implements AfterViewInit {
  @Input({ required: true }) students: StudentEligibleForEnrollmentUIModel[];
  @ViewChild('personCard', { static: true }) personCard!: TemplateRef<{ $implicit: string }>;
  @Output() primaryButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  sepTableType = SepTableType;

  columns: Array<Column<StudentEligibleForEnrollmentUIModel, string | { $implicit: string }>> = [
    {
      headerName: this.getTranslation('nameAndEmail'),
      renderComponent: undefined,
      key: 'username',
    },
  ];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
  ) {}

  ngAfterViewInit(): void {
    this.columns[0].renderComponent = this.personCard;
    this.cdr.detectChanges();
  }

  primaryButtonClick(): void {
    this.primaryButtonClickEvent.emit();
  }

  private getTranslation(key: string): string {
    return this.languageService.translateOnRuntime('portal.users.table.header.' + key);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationsResponseDTO } from '../models/OrganizationDTO';
import { PortalApiRepository } from '../../../../core/repositories/portal-api-repository';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends PortalApiRepository {
  constructor() {
    super();
  }

  getOrganizations(): Observable<OrganizationsResponseDTO> {
    const path = '/organizations';

    return this.get<OrganizationsResponseDTO>(path);
  }
}

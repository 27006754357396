import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CourseService } from '../../../domains/courses/services/course.service';
import { AsyncPipe } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';
import { EnrolledStudentTableComponent } from '../../../domains/courses/components/enrolled-student-table/enrolled-student-table.component';
import { CourseDataService } from '../../../domains/courses/services/course-data.service';
import { CourseEnrollmentNotificationService } from '../../../domains/courses/services/course-enrollment-notification.service';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
import { CourseInstanceUIModel } from '../../../domains/courses/models/CourseInstanceUIModel';
import { toCourseInstanceUIModel } from '../../../domains/courses/mappers/dto-mappers';
import { CourseStatisticsComponent } from '../../../domains/courses/components/course-statistics/course-statistics.component';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';
import { CustomPortalError } from '../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../core/services/snackbar.service';

@Component({
  selector: 'app-course-detail',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
    TranslocoPipe,
    EnrolledStudentTableComponent,
    LoadingComponent,
    CourseStatisticsComponent,
  ],
  templateUrl: './course-detail.component.html',
  styleUrl: './course-detail.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [easeInAnimationFast],
})
export class CourseDetailComponent implements OnInit, OnDestroy {
  translocoCourseDetailKeyPrefix = 'portal.courses.courseTitleKeys.';
  courseDetails$: BehaviorSubject<CourseInstanceUIModel | null> = new BehaviorSubject<CourseInstanceUIModel | null>(
    null,
  );
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly courseService: CourseService,
    private readonly courseDataService: CourseDataService,
    private readonly route: ActivatedRoute,
    private readonly courseEnrollementNotificationService: CourseEnrollmentNotificationService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.observeCourseIdInUrlAndFetch();

    /*Data needs to be refetched when new students when enrollment changed (enroll/unenroll).
     Therefore we subscribe to the course enrollment notification service.*/
    this.courseEnrollementNotificationService
      .getEnrollmentChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe((enrollmentChanged) => {
        if (enrollmentChanged) {
          const courseId = this.courseDataService.getCourseId();
          if (courseId) {
            this.getCourseDetail(courseId);
          }
        }
      });
  }

  private observeCourseIdInUrlAndFetch(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.courseDetails$.next(null);
      const id = params.get('id');
      if (id) {
        this.courseDataService.setCourseId(id);
        this.getCourseDetail(id);
      }
    });
  }

  getCourseDetail(id: string) {
    this.courseService.getCourseDetail(id).subscribe({
      next: (courseInstanceDetailResponse) => {
        const courseDetails = toCourseInstanceUIModel(courseInstanceDetailResponse);
        this.courseDetails$.next(courseDetails);
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  ngOnDestroy() {
    this.courseDetails$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}

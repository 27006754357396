import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SepTableComponent } from '../../../../shared/components/sep-table/sep-table.component';
import { UserCardComponent } from '../../../../shared/components/user-card/user-card.component';
import { TranslocoModule } from '@jsverse/transloco';
import { Router, ActivatedRoute } from '@angular/router';
import { UserUIModel } from '../../models/UserUIModel';
import { BadgeComponent } from '../../../../shared/components/badge/badge.component';
import { LicenseStatus } from '../../models/UserDTO';
import { LanguageService } from '../../../../core/services/language.service';
import { Column } from '../../../../shared/components/sep-table/Column';
import { TableRowAction } from '../../../../shared/components/sep-table/Action';
import { UserService } from '../../services/user.service';
import { UserCreationNotificationService } from '../../services/user-creation-notification.service';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CurrentUserState } from '../../../../core/store/current-user/current-user.state';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogModel } from '../../../../shared/components/dialog/dialog';
import { MatDialog } from '@angular/material/dialog';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { CourseEnrollmentNotificationService } from '../../../courses/services/course-enrollment-notification.service';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-user-table',
  standalone: true,
  imports: [SepTableComponent, UserCardComponent, TranslocoModule, BadgeComponent, MatIconModule, MatTooltipModule],
  templateUrl: './user-table.component.html',
})
export class UserTableComponent implements OnInit, AfterViewInit {
  @ViewChild('userCard', { static: true }) userCard!: TemplateRef<{ $implicit: string }>;
  @ViewChild('licenseBadge', { static: true }) licenseBadge!: TemplateRef<{ $implicit: string }>;
  @Input({ required: true }) users: UserUIModel[];
  @Select(CurrentUserState.userDetails) userDetails$: Observable<{ username?: string; email?: string }>;
  licenseStatus = LicenseStatus;

  columns: Array<Column<UserUIModel, string | { $implicit: string }>> = [
    {
      headerName: this.languageService.translateOnRuntime('portal.users.table.header.nameAndEmail'),
      renderComponent: undefined,
      key: 'username',
    },
    {
      headerName: this.languageService.translateOnRuntime('portal.users.table.header.license'),
      renderComponent: undefined,
      key: 'license',
    },
    {
      headerName: this.languageService.translateOnRuntime('portal.users.table.header.status'),
      field: 'accountStatus',
      key: 'accountStatus',
    },
    {
      headerName: this.languageService.translateOnRuntime('portal.users.table.header.added'),
      field: 'createdAt',
      key: 'createdAt',
    },
  ];

  rowActions: Array<TableRowAction<UserUIModel>> = [
    {
      label: this.languageService.translateOnRuntime('portal.users.table.actions.disable'),
      icon: 'manage_accounts',
      dynamicLabel: (user: UserUIModel) => this.getDynamicLabel(user),
      executeAction: (user: UserUIModel) => {
        if (user.accountEnabled) {
          this.disableUser(user);
        } else {
          this.enableUser(user);
        }
      },
      checkDisabled: (user: UserUIModel) => this.isCurrentUser(user),
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
    private readonly userService: UserService,
    private readonly userCreationNotificationService: UserCreationNotificationService,
    private readonly courseEnrollmentNotificationService: CourseEnrollmentNotificationService,
    private readonly snackbarService: SnackbarService,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.users = this.translateProperties(this.users);
  }

  ngAfterViewInit(): void {
    this.columns[0].renderComponent = this.userCard;
    this.columns[1].renderComponent = this.licenseBadge;
    this.cdr.detectChanges();
  }

  showUserCreationDialog() {
    void this.router.navigate(['dialog'], { relativeTo: this.activatedRoute, queryParams: { step: 'create' } });
  }

  showLicenseUnassignmentConfirmationDialog(user: UserUIModel) {
    const licenseUnassignmentConfirmationDialog = new DialogModel(this.dialog);
    licenseUnassignmentConfirmationDialog.closable = true;
    licenseUnassignmentConfirmationDialog.headline = this.languageService.translateOnRuntime(
      'portal.users.table.unassignLicenseConfirmationDialog.headline',
    );
    licenseUnassignmentConfirmationDialog.description = this.languageService.translateOnRuntime(
      'portal.users.table.unassignLicenseConfirmationDialog.description',
      { name: user.username },
    );
    licenseUnassignmentConfirmationDialog.buttonRowData = {
      confirmButtonText: this.languageService.translateOnRuntime(
        'portal.users.table.unassignLicenseConfirmationDialog.confirmButtonText',
      ),
      confirmButtonAction: () => {
        this.unassignLicense(user);
      },
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };

    licenseUnassignmentConfirmationDialog.openDialog();
  }

  unassignLicense(user: UserUIModel) {
    const licenseId = user.license?.id;
    if (!licenseId) {
      console.error('No license assigned to selected user');
      return;
    }
    this.userService.unassignLicense(licenseId).subscribe({
      next: () => {
        this.userCreationNotificationService.setUserChangedNotification();
        this.courseEnrollmentNotificationService.setEnrollmentChanged();
        this.analyticsService.track('license_unassigned');
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  private handleUserAction(action: () => Observable<void>) {
    action().subscribe({
      next: () => {
        this.userCreationNotificationService.setUserChangedNotification();
      },
    });
  }

  private enableUser(user: UserUIModel) {
    this.handleUserAction(() =>
      this.userService.enable([user]).pipe(
        tap(() => {
          this.analyticsService.track('user_enabled');
        }),
        catchError((error: CustomPortalError) => {
          this.snackbarService.error(error.errorType);
          return EMPTY;
        }),
      ),
    );
  }

  private disableUser(user: UserUIModel) {
    this.handleUserAction(() =>
      this.userService.disable([user]).pipe(
        tap(() => {
          this.analyticsService.track('user_disabled');
        }),
        catchError((error: CustomPortalError) => {
          this.snackbarService.error(error.errorType);
          return EMPTY;
        }),
      ),
    );
  }

  private getDynamicLabel(user: UserUIModel): string {
    if (this.isCurrentUser(user)) {
      return this.languageService.translateOnRuntime('portal.users.table.actions.cannotChangeStatusForCurrentUser');
    } else {
      return user.accountEnabled
        ? this.languageService.translateOnRuntime('portal.users.table.actions.disable')
        : this.languageService.translateOnRuntime('portal.users.table.actions.enable');
    }
  }

  private isCurrentUser(user: UserUIModel) {
    return this.store.selectSnapshot(CurrentUserState.userDetails).email === user.email;
  }

  private translateProperties(users: UserUIModel[]): UserUIModel[] {
    return users.map((user) => ({
      ...user,
      accountStatus: user.accountEnabled
        ? this.languageService.translateOnRuntime('portal.users.table.data.active')
        : this.languageService.translateOnRuntime('portal.users.table.data.inactive'),
    }));
  }
}

import { Component, OnInit } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { CourseInstancesResponseDTO } from '../../models/CourseDTO';
import { CourseInstanceInfoCardComponent } from '../course-instance-info-card/course-instance-info-card.component';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { CourseService } from '../../services/course.service';
import { easeInAnimationFast } from '../../../../shared/animations/ease-in-animation';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../../core/services/snackbar.service';

@Component({
  selector: 'app-course-instances-overview',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, CourseInstanceInfoCardComponent, CourseInstancesOverviewComponent],
  animations: [easeInAnimationFast],
  templateUrl: './course-instances-overview.component.html',
  styleUrl: './course-instances-overview.component.css',
})
export class CourseInstancesOverviewComponent implements OnInit {
  courseInstancesRes$: Observable<CourseInstancesResponseDTO>;
  constructor(
    private readonly courseService: CourseService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.courseInstancesRes$ = this.courseService.getCourseInstances().pipe(
      catchError((error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        return EMPTY;
      }),
    );
  }
}

import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { UserTableComponent } from '../../../domains/user/components/user-table/user-table.component';
import { UserManagementOverviewComponent } from '../../../domains/user/components/user-management-overview/user-management-overview.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { DataProcessingAgreementComponent } from '../../../domains/organization/components/data-processing-agreement/data-processing-agreement.component';
import { UserSettingsComponent } from '../../../domains/user/components/user-settings/user-settings.component';

@Component({
  selector: 'app-settings-container',
  standalone: true,
  imports: [
    MatTabsModule,
    UserTableComponent,
    UserManagementOverviewComponent,
    RouterModule,
    TranslocoModule,
    DataProcessingAgreementComponent,
    UserSettingsComponent,
  ],
  templateUrl: './settings-container.component.html',
})
export class SettingsContainerComponent {}

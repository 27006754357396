import { Component } from '@angular/core';
import { OrganizationsTableComponent } from '../../../domains/sep-admin/organizations/components/organizations-table/organizations-table.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-customer-overview',
  standalone: true,
  imports: [OrganizationsTableComponent, TranslocoDirective],
  templateUrl: './customer-overview.component.html',
  styleUrl: './customer-overview.component.css',
})
export class CustomerOverviewComponent {}

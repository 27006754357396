import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { LanguageService } from '../../../../core/services/language.service';

@Component({
  selector: 'app-user-settings',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent {
  activeLangCode: string;
  languages = [
    { code: 'de', label: 'Deutsch' },
    { code: 'en', label: 'English' },
  ];

  constructor(private readonly languageService: LanguageService) {
    this.activeLangCode = this.languageService.getActiveLanguage();
  }

  changeSiteLanguage(languageCode: string): void {
    this.activeLangCode = languageCode;
    this.languageService.setActiveLanguage(this.activeLangCode);
  }
}
